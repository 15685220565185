var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cont-video" }, [
    _c("div", { staticStyle: { padding: "72% 0 0 0", position: "relative" } }, [
      _c("iframe", {
        staticStyle: {
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        },
        attrs: {
          sandbox: "allow-scripts",
          src:
            "https://player.vimeo.com/video/" +
            _vm.src +
            "?muted=1&dnt=1&autoplay=" +
            _vm.autoplay,
          frameborder: "0",
          allowfullscreen: "",
          webkitallowfullscreen: "",
          mozallowfullscreen: "",
          title: "utopiq tool tutorial",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }