<template>
  <div class="cont-video">
    <div style="padding: 72% 0 0 0; position: relative">
      <iframe sandbox="allow-scripts" :src="'https://player.vimeo.com/video/' +
        src +
        '?muted=1&dnt=1&autoplay=' +
        autoplay
        " frameborder="0" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowfullscreen webkitallowfullscreen mozallowfullscreen title="utopiq tool tutorial" />
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoBlock",
  components: {},
  props: {
    src: {
    },
    autoplay: false,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
